var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.userAttributePlugin.id && _vm.userAttributes.length)?_c('div',{staticClass:"mb-5"},[_c('v-card',{staticClass:"border-radius mb-4 pt-5 px-4 pb-4",class:{ disabled: _vm.isLoading }},[_c('v-layout',{staticClass:"row wrap"},[_c('v-flex',{staticClass:"xs12 mb-4 title font-weight-regular"},[_vm._v(" "+_vm._s(_vm.userAttributePlugin.name)+" ")]),_c('v-flex',{staticClass:"xs12 mb-3"},[(_vm.userAttributes && _vm.userAttributes.length)?_c('v-layout',{staticClass:"row wrap"},_vm._l((_vm.userAttributes),function(attribute,i){return _c('v-flex',{key:'user-attribute-' + i,staticClass:"xs12"},[_c('v-flex',{staticClass:"xs8 mb-4"},[(_vm.attributeValues)?[(
                    attribute.type === 'options' && !attribute.is_collection
                  )?_c('v-select',{attrs:{"items":_vm.availableOptionsSingleSelect(
                      attribute.options,
                      attribute.slug,
                    ),"item-text":"name","item-value":"id","label":attribute.name,"menu-props":{ closeOnContentClick: true },"chips":"","small-chips":"","deletable-chips":"","disabled":!_vm.editMode},on:{"input":_vm.listGroupUserRelatedAttributes},model:{value:(_vm.attributeValues[attribute.slug]),callback:function ($$v) {_vm.$set(_vm.attributeValues, attribute.slug, $$v)},expression:"attributeValues[attribute.slug]"}}):_vm._e(),(
                    attribute.type === 'options' && attribute.is_collection
                  )?_c('v-select',{attrs:{"items":_vm.availableOptionsMultiSelect(
                      attribute.options,
                      attribute.slug,
                    ),"multiple":"","item-text":"name","item-value":"id","label":attribute.name,"menu-props":{ closeOnContentClick: true },"chips":"","small-chips":"","deletable-chips":"","disabled":!_vm.editMode},on:{"input":_vm.listGroupUserRelatedAttributes},model:{value:(_vm.attributeValues[attribute.slug]),callback:function ($$v) {_vm.$set(_vm.attributeValues, attribute.slug, $$v)},expression:"attributeValues[attribute.slug]"}}):_vm._e(),(attribute.type == 'text')?_c('v-textarea',{attrs:{"label":attribute.name,"no-resize":"","disabled":!_vm.editMode},model:{value:(_vm.attributeValues[attribute.slug]),callback:function ($$v) {_vm.$set(_vm.attributeValues, attribute.slug, $$v)},expression:"attributeValues[attribute.slug]"}}):_vm._e(),(
                    attribute.type == 'varchar' || attribute.type == 'integer'
                  )?_c('v-text-field',{attrs:{"label":attribute.name,"disabled":!_vm.editMode},model:{value:(_vm.attributeValues[attribute.slug]),callback:function ($$v) {_vm.$set(_vm.attributeValues, attribute.slug, $$v)},expression:"attributeValues[attribute.slug]"}}):_vm._e(),(attribute.type === 'datetime')?_c('CustomDatePicker',{attrs:{"label":attribute.name,"disabled":!_vm.editMode},model:{value:(_vm.attributeValues[attribute.slug]),callback:function ($$v) {_vm.$set(_vm.attributeValues, attribute.slug, $$v)},expression:"attributeValues[attribute.slug]"}}):_vm._e()]:_vm._e()],2)],1)}),1):_vm._e()],1),_c('v-flex',{staticClass:"xs12 text-center"},[(!_vm.editMode)?_c('v-btn',{staticClass:"ml-0 mr-3 sw-accent-bg sw-on-accent text-none",attrs:{"round":"","loading":_vm.isLoading},on:{"click":function($event){_vm.editMode = true}}},[_vm._v(_vm._s(_vm.$t("common.edit")))]):_vm._e(),(_vm.editMode)?_c('v-btn',{staticClass:"ml-0 mr-3 white sw-accent text-none",attrs:{"round":"","loading":_vm.isLoading},on:{"click":_vm.closeEditMode}},[_vm._v(_vm._s(_vm.$t("common.cancel")))]):_vm._e(),(_vm.editMode)?_c('v-btn',{staticClass:"ml-0 mr-3 sw-accent-bg sw-on-accent text-none",attrs:{"round":"","loading":_vm.isLoading},on:{"click":_vm.updateUser}},[_vm._v(_vm._s(_vm.$t("common.save")))]):_vm._e()],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }