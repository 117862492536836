<template>
  <v-container class="general">
    <page-title :component="'UsersPage'">
      <template v-slot:actions>
        <VideoTutorial
          :component="'UsersPage'"
          :title="$t('users-tutorial-title')"
        />
      </template>
    </page-title>
    <User />
    <Properties />
    <Attributes />
  </v-container>
</template>

<script>
import User from "@/components/GroupUserPage/User.vue";
import Properties from "@/components/GroupUserPage/Properties.vue";
import Attributes from "@/components/GroupUserPage/Attributes.vue";

export default {
  components: {
    User,
    Properties,
    Attributes,
  },
};
</script>

<style scoped lang="scss"></style>
